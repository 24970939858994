:root,
[data-mui-color-scheme="light"] {
    --mui-palette-action-disabledBackground: rgba(0, 0, 0, 0.06);
    --mui-palette-action-active: rgba(0, 0, 0, 0.54);
    --mui-palette-action-hover: rgba(0, 0, 0, 0.04);
    --mui-palette-action-hoverOpacity: 0.04;
    --mui-palette-action-selected: rgba(0, 0, 0, 0.2);
    --mui-palette-action-selectedOpacity: 0.2;
    --mui-palette-action-disabled: rgba(0, 0, 0, 0.26);
    --mui-palette-action-disabledOpacity: 0.38;
    --mui-palette-action-focus: rgba(0, 0, 0, 0.12);
    --mui-palette-action-focusOpacity: 0.12;
    --mui-palette-action-activatedOpacity: 0.12;
    --mui-palette-action-activeChannel: 0 0 0;
    --mui-palette-action-selectedChannel: 0 0 0;
    --mui-palette-background-default: var(--mui-palette-common-white);
    --mui-palette-background-defaultChannel: 255 255 255;
    --mui-palette-background-paper: var(--mui-palette-common-white);
    --mui-palette-background-level1: var(--mui-palette-neutral-50);
    --mui-palette-background-level2: var(--mui-palette-neutral-100);
    --mui-palette-background-level3: var(--mui-palette-neutral-200);
    --mui-palette-common-black: #000000;
    --mui-palette-common-white: #ffffff;
    --mui-palette-common-background: #fff;
    --mui-palette-common-onBackground: #000;
    --mui-palette-common-backgroundChannel: 255 255 255;
    --mui-palette-common-onBackgroundChannel: 0 0 0;
    --mui-palette-divider: var(--mui-palette-neutral-200);
    --mui-palette-dividerChannel: 220 223 228;
    --mui-palette-error-50: #fef3f2;
    --mui-palette-error-100: #fee4e2;
    --mui-palette-error-200: #ffcdc9;
    --mui-palette-error-300: #fdaaa4;
    --mui-palette-error-400: #f97970;
    --mui-palette-error-500: #f04438;
    --mui-palette-error-600: #de3024;
    --mui-palette-error-700: #bb241a;
    --mui-palette-error-800: #9a221a;
    --mui-palette-error-900: #80231c;
    --mui-palette-error-950: #460d09;
    --mui-palette-error-light: #f97970;
    --mui-palette-error-main: #f04438;
    --mui-palette-error-dark: #de3024;
    --mui-palette-error-contrastText: var(--mui-palette-common-white);
    --mui-palette-error-mainChannel: 240 68 56;
    --mui-palette-error-lightChannel: 249 121 112;
    --mui-palette-error-darkChannel: 222 48 36;
    --mui-palette-error-contrastTextChannel: var(--mui-palette-common-white);
    --mui-palette-info-50: #ecfdff;
    --mui-palette-info-100: #cff7fe;
    --mui-palette-info-200: #a4eefd;
    --mui-palette-info-300: #66e0fa;
    --mui-palette-info-400: #10bee8;
    --mui-palette-info-500: #04aad6;
    --mui-palette-info-600: #0787b3;
    --mui-palette-info-700: #0d6d91;
    --mui-palette-info-800: #145876;
    --mui-palette-info-900: #154964;
    --mui-palette-info-950: #082f44;
    --mui-palette-info-light: #10bee8;
    --mui-palette-info-main: #04aad6;
    --mui-palette-info-dark: #0787b3;
    --mui-palette-info-contrastText: var(--mui-palette-common-white);
    --mui-palette-info-mainChannel: 4 170 214;
    --mui-palette-info-lightChannel: 16 190 232;
    --mui-palette-info-darkChannel: 7 135 179;
    --mui-palette-info-contrastTextChannel: var(--mui-palette-common-white);
    --mui-palette-neutral-50: #f9fafb;
    --mui-palette-neutral-100: #f1f1f4;
    --mui-palette-neutral-200: #dcdfe4;
    --mui-palette-neutral-300: #b3b9c6;
    --mui-palette-neutral-400: #8a94a6;
    --mui-palette-neutral-500: #667085;
    --mui-palette-neutral-600: #565e73;
    --mui-palette-neutral-700: #434a60;
    --mui-palette-neutral-800: #313749;
    --mui-palette-neutral-900: #212636;
    --mui-palette-neutral-950: #121621;
    --mui-palette-primary-50: #ecf0ff;
    --mui-palette-primary-100: #dde3ff;
    --mui-palette-primary-200: #c2cbff;
    --mui-palette-primary-300: #9ca7ff;
    --mui-palette-primary-400: #7578ff;
    --mui-palette-primary-500: #635bff;
    --mui-palette-primary-600: #4e36f5;
    --mui-palette-primary-700: #432ad8;
    --mui-palette-primary-800: #3725ae;
    --mui-palette-primary-900: #302689;
    --mui-palette-primary-950: #1e1650;
    --mui-palette-primary-light: #7578ff;
    --mui-palette-primary-main: #635bff;
    --mui-palette-primary-dark: #4e36f5;
    --mui-palette-primary-contrastText: var(--mui-palette-common-white);
    --mui-palette-primary-mainChannel: 99 91 255;
    --mui-palette-primary-lightChannel: 117 120 255;
    --mui-palette-primary-darkChannel: 78 54 245;
    --mui-palette-primary-contrastTextChannel: var(--mui-palette-common-white);
    --mui-palette-secondary-50: #fbfcfe;
    --mui-palette-secondary-100: #f0f4f8;
    --mui-palette-secondary-200: #dde7ee;
    --mui-palette-secondary-300: #cdd7e1;
    --mui-palette-secondary-400: #9fa6ad;
    --mui-palette-secondary-500: #636b74;
    --mui-palette-secondary-600: #555e68;
    --mui-palette-secondary-700: #32383e;
    --mui-palette-secondary-800: #202427;
    --mui-palette-secondary-900: #121517;
    --mui-palette-secondary-950: #090a0b;
    --mui-palette-secondary-light: #555e68;
    --mui-palette-secondary-main: #32383e;
    --mui-palette-secondary-dark: #202427;
    --mui-palette-secondary-contrastText: var(--mui-palette-common-white);
    --mui-palette-secondary-mainChannel: 50 56 62;
    --mui-palette-secondary-lightChannel: 85 94 104;
    --mui-palette-secondary-darkChannel: 32 36 39;
    --mui-palette-secondary-contrastTextChannel: var(--mui-palette-common-white);
    --mui-palette-success-50: #f0fdfa;
    --mui-palette-success-100: #ccfbef;
    --mui-palette-success-200: #9af5e1;
    --mui-palette-success-300: #5fe9ce;
    --mui-palette-success-400: #2ed3b8;
    --mui-palette-success-500: #15b79f;
    --mui-palette-success-600: #0e9382;
    --mui-palette-success-700: #107569;
    --mui-palette-success-800: #115e56;
    --mui-palette-success-900: #134e48;
    --mui-palette-success-950: #042f2c;
    --mui-palette-success-light: #2ed3b8;
    --mui-palette-success-main: #15b79f;
    --mui-palette-success-dark: #0e9382;
    --mui-palette-success-contrastText: var(--mui-palette-common-white);
    --mui-palette-success-mainChannel: 21 183 159;
    --mui-palette-success-lightChannel: 46 211 184;
    --mui-palette-success-darkChannel: 14 147 130;
    --mui-palette-success-contrastTextChannel: var(--mui-palette-common-white);
    --mui-palette-text-primary: var(--mui-palette-neutral-900);
    --mui-palette-text-primaryChannel: 33 38 54;
    --mui-palette-text-secondary: var(--mui-palette-neutral-600);
    --mui-palette-text-secondaryChannel: 102 112 133;
    --mui-palette-text-disabled: var(--mui-palette-neutral-400);
    --mui-palette-warning-50: #fffaea;
    --mui-palette-warning-100: #fff3c6;
    --mui-palette-warning-200: #ffe587;
    --mui-palette-warning-300: #ffd049;
    --mui-palette-warning-400: #ffbb1f;
    --mui-palette-warning-500: #fb9c0c;
    --mui-palette-warning-600: #de7101;
    --mui-palette-warning-700: #b84d05;
    --mui-palette-warning-800: #953b0b;
    --mui-palette-warning-900: #7b310c;
    --mui-palette-warning-950: #471701;
    --mui-palette-warning-light: #ffbb1f;
    --mui-palette-warning-main: #fb9c0c;
    --mui-palette-warning-dark: #de7101;
    --mui-palette-warning-contrastText: var(--mui-palette-common-white);
    --mui-palette-warning-mainChannel: 251 156 12;
    --mui-palette-warning-lightChannel: 255 187 31;
    --mui-palette-warning-darkChannel: 222 113 1;
    --mui-palette-warning-contrastTextChannel: var(--mui-palette-common-white);
    --mui-palette-grey-50: #fafafa;
    --mui-palette-grey-100: #f5f5f5;
    --mui-palette-grey-200: #eeeeee;
    --mui-palette-grey-300: #e0e0e0;
    --mui-palette-grey-400: #bdbdbd;
    --mui-palette-grey-500: #9e9e9e;
    --mui-palette-grey-600: #757575;
    --mui-palette-grey-700: #616161;
    --mui-palette-grey-800: #424242;
    --mui-palette-grey-900: #212121;
    --mui-palette-grey-A100: #f5f5f5;
    --mui-palette-grey-A200: #eeeeee;
    --mui-palette-grey-A400: #bdbdbd;
    --mui-palette-grey-A700: #616161;
    --mui-palette-Alert-errorColor: rgb(99, 48, 44);
    --mui-palette-Alert-infoColor: rgb(6, 76, 92);
    --mui-palette-Alert-successColor: rgb(18, 84, 73);
    --mui-palette-Alert-warningColor: rgb(102, 74, 12);
    --mui-palette-Alert-errorFilledBg: var(--mui-palette-error-main, #f04438);
    --mui-palette-Alert-infoFilledBg: var(--mui-palette-info-main, #04aad6);
    --mui-palette-Alert-successFilledBg: var(--mui-palette-success-main, #15b79f);
    --mui-palette-Alert-warningFilledBg: var(--mui-palette-warning-main, #fb9c0c);
    --mui-palette-Alert-errorFilledColor: #fff;
    --mui-palette-Alert-infoFilledColor: rgba(0, 0, 0, 0.87);
    --mui-palette-Alert-successFilledColor: rgba(0, 0, 0, 0.87);
    --mui-palette-Alert-warningFilledColor: rgba(0, 0, 0, 0.87);
    --mui-palette-Alert-errorStandardBg: rgb(254, 241, 240);
    --mui-palette-Alert-infoStandardBg: rgb(231, 248, 252);
    --mui-palette-Alert-successStandardBg: rgb(234, 250, 247);
    --mui-palette-Alert-warningStandardBg: rgb(255, 248, 232);
    --mui-palette-Alert-errorIconColor: var(--mui-palette-error-main, #f04438);
    --mui-palette-Alert-infoIconColor: var(--mui-palette-info-main, #04aad6);
    --mui-palette-Alert-successIconColor: var(--mui-palette-success-main, #15b79f);
    --mui-palette-Alert-warningIconColor: var(--mui-palette-warning-main, #fb9c0c);
    --mui-palette-AppBar-defaultBg: var(--mui-palette-grey-100, #f5f5f5);
    --mui-palette-Avatar-defaultBg: var(--mui-palette-grey-400, #bdbdbd);
    --mui-palette-Button-inheritContainedBg: var(--mui-palette-grey-300, #e0e0e0);
    --mui-palette-Button-inheritContainedHoverBg: var(--mui-palette-grey-A100, #f5f5f5);
    --mui-palette-Chip-defaultBorder: var(--mui-palette-grey-400, #bdbdbd);
    --mui-palette-Chip-defaultAvatarColor: var(--mui-palette-grey-700, #616161);
    --mui-palette-Chip-defaultIconColor: var(--mui-palette-grey-700, #616161);
    --mui-palette-FilledInput-bg: rgba(0, 0, 0, 0.06);
    --mui-palette-FilledInput-hoverBg: rgba(0, 0, 0, 0.09);
    --mui-palette-FilledInput-disabledBg: rgba(0, 0, 0, 0.12);
    --mui-palette-LinearProgress-primaryBg: rgb(195, 192, 255);
    --mui-palette-LinearProgress-secondaryBg: rgb(177, 179, 181);
    --mui-palette-LinearProgress-errorBg: rgb(249, 183, 179);
    --mui-palette-LinearProgress-infoBg: rgb(159, 222, 239);
    --mui-palette-LinearProgress-successBg: rgb(166, 227, 218);
    --mui-palette-LinearProgress-warningBg: rgb(253, 217, 162);
    --mui-palette-Skeleton-bg: rgba(var(--mui-palette-text-primaryChannel, 33 38 54) / 0.11);
    --mui-palette-Slider-primaryTrack: rgb(195, 192, 255);
    --mui-palette-Slider-secondaryTrack: rgb(177, 179, 181);
    --mui-palette-Slider-errorTrack: rgb(249, 183, 179);
    --mui-palette-Slider-infoTrack: rgb(159, 222, 239);
    --mui-palette-Slider-successTrack: rgb(166, 227, 218);
    --mui-palette-Slider-warningTrack: rgb(253, 217, 162);
    --mui-palette-SnackbarContent-bg: var(--mui-palette-common-white);
    --mui-palette-SpeedDialAction-fabHoverBg: var(--mui-palette-common-white);
    --mui-palette-StepConnector-border: var(--mui-palette-grey-400, #bdbdbd);
    --mui-palette-StepContent-border: var(--mui-palette-grey-400, #bdbdbd);
    --mui-palette-Switch-defaultColor: var(--mui-palette-common-white, #ffffff);
    --mui-palette-Switch-defaultDisabledColor: var(--mui-palette-grey-100, #f5f5f5);
    --mui-shape-borderRadius: 8px;
    --mui-shadows-0: none;
    --mui-shadows-1: 0px 1px 2px rgba(0, 0, 0, 0.2);
    --mui-shadows-2: 0px 1px 5px rgba(0, 0, 0, 0.2);
    --mui-shadows-3: 0px 1px 8px rgba(0, 0, 0, 0.2);
    --mui-shadows-4: 0px 1px 10px rgba(0, 0, 0, 0.2);
    --mui-shadows-5: 0px 1px 14px rgba(0, 0, 0, 0.2);
    --mui-shadows-6: 0px 1px 18px rgba(0, 0, 0, 0.2);
    --mui-shadows-7: 0px 2px 16px rgba(0, 0, 0, 0.2);
    --mui-shadows-8: 0px 3px 14px rgba(0, 0, 0, 0.2);
    --mui-shadows-9: 0px 3px 16px rgba(0, 0, 0, 0.2);
    --mui-shadows-10: 0px 4px 18px rgba(0, 0, 0, 0.2);
    --mui-shadows-11: 0px 4px 20px rgba(0, 0, 0, 0.2);
    --mui-shadows-12: 0px 5px 22px rgba(0, 0, 0, 0.2);
    --mui-shadows-13: 0px 5px 24px rgba(0, 0, 0, 0.2);
    --mui-shadows-14: 0px 5px 26px rgba(0, 0, 0, 0.2);
    --mui-shadows-15: 0px 6px 28px rgba(0, 0, 0, 0.2);
    --mui-shadows-16: 0px 6px 30px rgba(0, 0, 0, 0.2);
    --mui-shadows-17: 0px 6px 32px rgba(0, 0, 0, 0.2);
    --mui-shadows-18: 0px 7px 34px rgba(0, 0, 0, 0.2);
    --mui-shadows-19: 0px 7px 36px rgba(0, 0, 0, 0.2);
    --mui-shadows-20: 0px 8px 38px rgba(0, 0, 0, 0.2);
    --mui-shadows-21: 0px 8px 40px rgba(0, 0, 0, 0.2);
    --mui-shadows-22: 0px 8px 42px rgba(0, 0, 0, 0.2);
    --mui-shadows-23: 0px 9px 44px rgba(0, 0, 0, 0.2);
    --mui-shadows-24: 0px 9px 46px rgba(0, 0, 0, 0.2);
    --mui-zIndex-mobileStepper: 1000;
    --mui-zIndex-fab: 1050;
    --mui-zIndex-speedDial: 1050;
    --mui-zIndex-appBar: 1100;
    --mui-zIndex-drawer: 1200;
    --mui-zIndex-modal: 1300;
    --mui-zIndex-snackbar: 1400;
    --mui-zIndex-tooltip: 1500;



    --dashboard-left-side-width: 20%;
    --dashboard-right-side-width: 80%;
    --dashboard-left-side-width-large: 30%;
    --dashboard-right-side-width-large: 70%;
    --header-height: 80px;


    --mobile-dashboard-left-side-width: 50%;
    --mobile-dashboard-right-side-width: 50%;
    --mobile-dashboard-left-side-width-large: 50%;
    --mobile-dashboard-right-side-width-large: 50%;
    --mobile-header-height: 200px;
}


.button {
    border-radius: var(--mui-shape-borderRadius) !important;
}

.button.rounded {
    border-radius: 50% !important;
}

.button.small {
    font-size: small;
}

.button.medium {
    font-size: medium;
}

.button.large {
    font-size: large;
}

.button.xlarge {
    font-size: x-large;
}

.button.xxlarge {
    font-size: xx-large;
}

.button.bold {
    font-weight: bold;
}

.button.primary:disabled,
.button.secondary:disabled,
.button.error:disabled,
.button.success:disabled {
    background-color: var(--mui-palette-text-disabled) !important;
}

.button.primary {
    color: var(--mui-palette-primary-contrastText) !important;
    background-color: var(--mui-palette-primary-main) !important;
}

.button.primary:hover,
.button.primary.active {
    background-color: var(--mui-palette-primary-dark) !important;
    box-shadow: var(--mui-shadows-4);
}



.button.secondary {
    color: var(--mui-palette-secondary-contrastText) !important;
    background-color: var(--mui-palette-secondary-main) !important;
}

.button.secondary:hover,
.button.secondary.active {
    background-color: var(--mui-palette-secondary-dark) !important;
    box-shadow: var(--mui-shadows-4);
}


.button.success {
    color: var(--mui-palette-primary-contrastText) !important;
    background-color: rgb(52, 168, 83) !important;
}

.button.success:hover,
.button.success.active {
    color: var(--mui-palette-primary-contrastText) !important;
    background-color: rgb(64, 119, 79) !important;
}

.button.error {
    color: var(--mui-palette-primary-contrastText) !important;
    background-color: var(--mui-palette-error-600);
}

.button.error:hover,
.button.error.active {
    color: var(--mui-palette-primary-contrastText) !important;
    background-color: var(--mui-palette-error-700);
}




.input {
    border-radius: var(--mui-shape-borderRadius) !important;
}

.input.primary input,
.input.primary label,
.input.primary .MuiSelect-select {
    color: var(--mui-palette-text-primary) !important;
}

.input.primary label.Mui-focused {
    color: var(--mui-palette-primary-main) !important;
}

.input.primary .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--mui-palette-primary-main) !important;
}


.input.primary2 input,
.input.primary2 label,
.input.primary2 .MuiSelect-select {
    color: var(--mui-palette-neutral-100) !important;
}

.input.primary2 .MuiOutlinedInput-notchedOutline {
    border-color: var(--mui-palette-neutral-100) !important;
}

.input.primary2 label.Mui-focused {
    color: var(--mui-palette-neutral-50) !important;
}

.input.primary2 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--mui-palette-primary-main) !important;
}


.input.secondary input,
.input.secondary label,
.input.secondary .MuiSelect-select {
    color: var(--mui-palette-text-primary) !important;
}

.input.secondary label.Mui-focused {
    color: var(--mui-palette-primary-secondary) !important;
}

.input.secondary .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--mui-palette-primary-secondary) !important;
}



.text.primary {
    color: var(--mui-palette-text-primary);
}

.text.secondary {
    color: var(--mui-palette-text-secondary);
}

.text.primary2 {
    color: var(--mui-palette-secondary-100);
}

.text.secondary2 {
    color: var(--mui-palette-secondary-50);
}


.text.small {
    font-size: small;
}

.text.medium {
    font-size: medium;
}

.text.large {
    font-size: large;
}

.text.xlarge {
    font-size: x-large;
}

.text.xxlarge {
    font-size: xx-large;
}


.text.center {
    text-align: center;
}

.text.bold {
    font-weight: bold;
}




.card-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    border-radius: var(--mui-shape-borderRadius) !important;
    box-shadow: var(--mui-shadows-10) !important;
    background-color: var(--mui-palette-common-background)
}

.card-container.center {
    top: 25vh;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}


.shadow-low {
    box-shadow: var(--mui-shadows-5) !important;
}

.shadow-medium {
    box-shadow: var(--mui-shadows-15) !important;
}

.shadow-high {
    box-shadow: var(--mui-shadows-24) !important;
}







body {
    margin: 0px;
    font-family: Arial, Helvetica, sans-serif !important;
}


.subheader-container {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 15;

    transition: 0.2s;
}

.sidebar-body.open>.subheader-container {
    left: var(--dashboard-left-side-width);
    transition: 0.2s;
}

.subheader-container .sidebar {
    max-height: 88vh;
    margin-left: calc(-1 * var(--dashboard-left-side-width-large));
}

.subheader-container .sidebar.open {
    width: var(--dashboard-left-side-width-large);
}

.subheader-container~.container .sidebar-body.open {
    margin-left: calc(1 * var(--dashboard-left-side-width-large));
}

.subheader-container~.container .sidebar-body.open>.loader {
    width: var(--dashboard-right-side-width-large);
    left: var(--dashboard-left-side-width-large);
}





.header-container {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 100;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--mui-palette-neutral-700) !important;
    box-shadow: var(--mui-shadows-24) !important;
    padding: 20px;
    max-height: 40px;
}



.header-navbar {
    flex: 2;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    list-style: none;

}




.nav-item {
    position: relative;
}

.nav-link {
    padding: 15px;
    border: 1px solid #04aad6;
    color: #ecf0f1;
    font-weight: bold;
    border-radius: 3px;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nav-item.active .nav-link {
    background-color: #3498db;
    color: #fff;
}

.nav-link:hover {
    background-color: #2980b9;
    color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-navbar {
        width: 100%;
    }

    .nav {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-item {
        margin: 5px 0;
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s ease-in-out;
    z-index: 10;
}

.fade-in {
    opacity: 1;
    pointer-events: all;
}

.fade-out {
    opacity: 0;
    pointer-events: none;
}


.loader img {
    width: 50%;
}

.sidebar-body.open>.loader {
    width: var(--dashboard-right-side-width);
    left: var(--dashboard-left-side-width);
    transition: 0.2s;
}



.container {
    min-height: 100vh;
    background-color: var(--mui-palette-background-level2);
    padding: 2%;
    position: relative;
    z-index: 1;
}

.container.empty {
    padding: 0;
}



.simulation-result {
    padding: 2%;
    margin: 1%;
    box-shadow: 1px 1px 20px 1px #000;
    border-radius: 5px;
    background-color: #fff;
}






.sidebar {
    width: 0%;
    margin-left: calc(-1 * var(--dashboard-left-side-width));

    position: absolute;
    background-color: var(--mui-palette-neutral-800) !important;
    box-shadow: var(--mui-shadows-24) !important;
    overflow: auto;

    height: 100vh;

    transition: 0.5s;
}

.sidebar.open {
    width: var(--dashboard-left-side-width);
    margin-left: 0;
    transition: 0.2s;
}




.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3% 3%;
}



.sidebar-body {
    position: relative;
    width: -webkit-fill-available;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: var(--header-height);


    transition: 0.5s;
}

.sidebar-body.open {
    margin-left: calc(1 * var(--dashboard-left-side-width));
    transition: 0.2s;
}





.cards {
    display: flex;
    align-items: center;
}


.card {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    box-shadow: 1px 1px 10px 1px #00000067 !important;
}


.card-title {
    padding: 10px !important;
}

.card-title .MuiTypography-root {
    font-size: 20px !important;
}


.card-content {
    padding: 20px !important;
    font-weight: bold;
    font-size: 20px;
}



.icon-bg {
    display: flex;
    justify-content: center;
    align-items: center;
}


.icon-bg .MuiSvgIcon-root {
    font-size: 300px;
    color: #2c3e50;
}

.icon-bg-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}


.icon-bg-msg {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    color: #2c3e50;
}







.leaflet-draw-section {
    display: none;
}




.icon.center {
    text-align: center;
}

.icon.primary svg {
    color: var(--mui-palette-text-primary);
}

.icon.secondary svg {
    color: var(--mui-palette-text-secondary);
}

.icon.primary2 svg {
    color: var(--mui-palette-secondary-100);
}

.icon.medium svg {
    font-size: 1rem;
}

.icon.large svg {
    font-size: 2rem;
}

.icon.xlarge svg {
    font-size: 3rem;
}




.divider {
    width: 100%;
}

.divider.small {
    padding: 0.1px;
    margin: 2% 0px;
}

.divider.medium {
    padding: 0.5px;
    margin: 2% 0px;
}

.divider.large {
    padding: 1px;
    margin: 4% 0px;
}

.divider.xlarge {
    padding: 2px;
    margin: 5% 0px;
}

.divider.primary {
    background-color: var(--mui-palette-text-primary) !important;
}

.divider.primary2 {
    background-color: var(--mui-palette-secondary-100) !important;
}

.divider.secondary {
    background-color: var(--mui-palette-text-secondary) !important;
}




.pt-small {
    padding-top: 5px !important;
}

.pt-medium {
    padding-top: 20px !important;
}

.pt-large {
    padding-top: 40px !important;
}

.pb-small {
    padding-bottom: 5px !important;
}

.pb-medium {
    padding-bottom: 20px !important;
}

.pb-large {
    padding-bottom: 40px !important;
}

.pr-small {
    padding-right: 5px !important;
}

.pr-medium {
    padding-right: 20px !important;
}

.pr-large {
    padding-right: 40px !important;
}

.pl-small {
    padding-left: 5px !important;
}

.pl-medium {
    padding-left: 20px !important;
}

.pl-large {
    padding-left: 40px !important;
}


.mt-small {
    margin-top: 5px !important;
}

.mt-medium {
    margin-top: 20px !important;
}

.mt-large {
    margin-top: 40px !important;
}

.mb-small {
    margin-bottom: 5px !important;
}

.mb-medium {
    margin-bottom: 20px !important;
}

.mb-large {
    margin-bottom: 40px !important;
}

.mr-small {
    margin-right: 5px !important;
}

.mr-medium {
    margin-right: 20px !important;
}

.mr-large {
    margin-right: 40px !important;
}

.ml-small {
    margin-left: 5px !important;
}

.ml-medium {
    margin-left: 20px !important;
}

.ml-large {
    margin-left: 40px !important;
}








.carousel .control-arrow {
    width: 8%;
}



.carousel .slide img {
    height: 90vh;
    object-fit: cover;
}












.nav.mobile-menu {
    display: none;
}

.nav.mobile-menu .MuiSvgIcon-root {
    color: #fff;
}




.home-section3 .card-icon .MuiSvgIcon-root {
    font-size: 4rem;
}

.home-section3 .card-icon .title {
    font-size: 1.5rem;
}

.home-section3 .card-icon .sub-title {
    font-size: 1.3rem !important;
}



.home-section4 .card-icon .icon {
    width: 100% !important;
}


@media(max-width: 1024px) {
    .nav {
        display: none;
    }

    .nav.mobile-menu {
        display: flex;
    }

    .nav.mobile-menu .MuiSvgIcon-root {
        font-size: 4rem;
    }



    .icon-text .text {
        font-size: 2rem;
    }

    .icon-text .icon .MuiSvgIcon-root {
        font-size: 3rem;
    }

    .nav .MuiAvatar-root {
        font-size: 3rem;
        height: 3vh;
        width: 3vh;
    }

    .nav .icon .MuiSvgIcon-root {
        font-size: 3rem;
    }

    .header {
        max-height: 80px;
        padding-top: 60px;
        padding-bottom: 60px;
    }


    .slogan .MuiAvatar-root {
        width: 3.5vh;
        height: 3.5vh;
    }

    .slogan .title {
        font-size: 2rem;
    }



    .carousel-card {
        width: 80%;
    }

    .carousel-card .title {
        font-size: 5rem !important;
    }

    .carousel-card .sub-title {
        font-size: 3rem !important;
    }


    .home-section2 {
        padding: 2vh !important;
    }

    .home-section2 .section-title {
        font-size: 4rem !important;
    }

    .home-section2 .MuiButtonBase-root {
        font-size: 2.5rem;
    }

    .home-section2 .sub-title {
        font-size: 2rem;
    }



    .home-section3 .section-title {
        font-size: 3rem !important;
    }



    .home-section3 .card-icon .MuiSvgIcon-root {
        font-size: 4rem;
    }

    .home-section3 .card-icon .title {
        font-size: 3rem !important;
    }

    .home-section3 .card-icon .sub-title {
        font-size: 2rem !important;
    }



    .home-section4 .section-title {
        font-size: 3rem;
    }

    .home-section4 .title {
        font-size: 2rem;
    }


    .home-section5 .text {
        font-size: 3rem;
    }


    .home-section6 .section-title {
        font-size: 3rem !important;
    }


    .home-section6 {
        padding: 2vh !important;
    }

    .home-section6 .text {
        font-size: 2rem;
    }



    .footer .cards-grid .title {
        font-size: 3rem;
    }

    .footer .cards-grid .sub-title {
        font-size: 2.5rem;
    }

    .footer .cards-grid .MuiSvgIcon-root {
        font-size: 7rem !important;
    }

    .footer-section2 .title {
        font-size: 3rem;
    }

    .footer-section2 .sub-title {
        font-size: 1.5rem;
    }


    .footer-section2 .MuiFormControl-root {
        padding: 1rem;
    }

    .footer-section2 .MuiFormLabel-root,
    .footer-section2 .MuiInputBase-root {
        font-size: 2rem;
    }

    .footer-section2 .MuiSvgIcon-root {
        font-size: 4rem;
    }


    .footer-section3 .title {
        font-size: 3rem;
    }

    .footer-section3 .MuiSvgIcon-root {
        font-size: 4rem;
    }



    .footer-section4 .MuiButtonBase-root {
        width: 100%;
    }

    .footer-section4 .title {
        font-size: 2rem;
    }






    .container.empty .card-container {
        width: 80% !important;
    }

    .container.empty .logo {
        width: 25% !important;
    }

    .container.empty .title {
        font-size: 4rem !important;
    }

    .container.empty .sub-title {
        font-size: 2rem !important;
    }


    .container.empty .MuiFormLabel-root {
        font-size: 2rem;
    }

    .container.empty .MuiInputBase-root {
        font-size: 2rem;
    }

    .container.empty .MuiButtonBase-root {
        font-size: 2.5rem;
    }

    .container.empty .MuiSvgIcon-root {
        font-size: 3rem;
    }

    .container.empty .card-container {
        top: 35vh !important;
    }

    .bg-section .bg-title {
        font-size: 6rem !important;
    }

    .bg-section .bg-sub-title {
        font-size: 3rem !important;
    }


    .bg-section .bg-img {
        height: 60vh !important;
    }








    .sidebar-body.open>.subheader-container {
        left: var(--mobile-dashboard-left-side-width);
    }

    .subheader-container .sidebar {
        margin-left: calc(-1 * var(--mobile-dashboard-left-side-width-large));
    }

    .subheader-container .sidebar.open {
        width: var(--mobile-dashboard-left-side-width-large);
    }

    .subheader-container~.container .sidebar-body.open {
        margin-left: 0%;
    }

    .subheader-container~.container .sidebar-body.open>.loader {
        width: var(--mobile-dashboard-right-side-width-large);
        left: var(--mobile-dashboard-left-side-width-large);
    }




    .sidebar-body.open>.loader {
        width: var(--mobile-dashboard-right-side-width);
        left: var(--mobile-dashboard-left-side-width);
    }


    .sidebar {
        margin-left: calc(-1 * var(--mobile-dashboard-left-side-width));
        transition: 0.5s;
    }

    .sidebar.open {
        width: var(--mobile-dashboard-left-side-width);
        margin-left: 0;
        transition: 0.2s;
    }


    .sidebar-body {
        margin-top: var(--mobile-header-height);
        transition: 0.5s;
    }

    .sidebar-body.open {
        margin-left: 0%;
        transition: 0.2s;
    }



    .header .title {
        font-size: 2rem;
    }


    .header .MuiSvgIcon-root {
        font-size: 3rem;
    }



    .main-sidebar .main-sidebar .MuiButtonBase-root {
        font-size: 2rem;
    }

    .main-sidebar .main-sidebar svg {
        font-size: 4rem !important;
    }






    .text.small {
        font-size: 1rem !important;
    }

    .text.medium {
        font-size: 2rem !important;
    }

    .text.large {
        font-size: 2.2rem !important;
    }

    .text.xlarge {
        font-size: 2.5rem !important;
    }

    .text.xxlarge {
        font-size: 3rem !important;
    }


    .pt-small {
        padding-top: 10px !important;
    }

    .pt-medium {
        padding-top: 40px !important;
    }

    .pt-large {
        padding-top: 60px !important;
    }

    .pb-small {
        padding-bottom: 10px !important;
    }

    .pb-medium {
        padding-bottom: 40px !important;
    }

    .pb-large {
        padding-bottom: 60px !important;
    }

    .pr-small {
        padding-right: 10px !important;
    }

    .pr-medium {
        padding-right: 40px !important;
    }

    .pr-large {
        padding-right: 60px !important;
    }

    .pl-small {
        padding-left: 10px !important;
    }

    .pl-medium {
        padding-left: 40px !important;
    }

    .pl-large {
        padding-left: 60px !important;
    }


    .mt-small {
        margin-top: 10px !important;
    }

    .mt-medium {
        margin-top: 40px !important;
    }

    .mt-large {
        margin-top: 60px !important;
    }

    .mb-small {
        margin-bottom: 10px !important;
    }

    .mb-medium {
        margin-bottom: 40px !important;
    }

    .mb-large {
        margin-bottom: 60px !important;
    }

    .mr-small {
        margin-right: 10px !important;
    }

    .mr-medium {
        margin-right: 40px !important;
    }

    .mr-large {
        margin-right: 60px !important;
    }

    .ml-small {
        margin-left: 10px !important;
    }

    .ml-medium {
        margin-left: 40px !important;
    }

    .ml-large {
        margin-left: 60px !important;
    }











    .button.small {
        font-size: 1rem;
    }

    .button.medium {
        font-size: 2rem;
    }

    .button.large {
        font-size: 2.2rem;
    }

    .button.xlarge {
        font-size: 2.5rem;
    }

    .button.xxlarge {
        font-size: 3rem;
    }




    .MuiTableCell-root {
        font-size: 1.5rem !important;
    }

    .MuiTableCell-root svg {
        font-size: 3rem;
    }

    .MuiFormLabel-root,
    .MuiInputBase-root {
        font-size: 2.5rem !important;
    }

    .MuiSelect-select {
        font-size: 2rem !important;
    }




    .rbc-button-link {
        font-size: 2rem;
    }

    .rbc-btn-group button {
        font-size: 3rem;
    }

    .rbc-header span {
        font-size: 2rem;
    }

    .rbc-toolbar-label {
        font-size: 3rem;
    }

    .rbc-toolbar {
        justify-content: start !important;
    }

    .rbc-event {
        font-size: 2rem;
    }


    .loader img {
        width: 100% !important;
    }




    .MuiMenuItem-root {
        font-size: 2rem !important;
    }

    .MuiAutocomplete-listbox {
        font-size: 2rem !important;
    }

    .MuiChip-root {
        font-size: 2rem !important;
    }
}